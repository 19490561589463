import * as React from "react";
import { inject, observer } from "mobx-react";
import { HeroBanner, HeroBannerViews } from "@shared-ui/travel-content-editorial";
import UriContextStore from "stores/UriContextStore/UriContextStore";
import { mapAnalyticsData } from "components/utility/AnalyticsData";
import { ComponentProps } from "typings/flexFramework/FlexComponent";
import { ExtendedTemplateComponent, ExtendedTemplateComponentConfig } from "stores/ExperienceTemplateStore/typings";
import { usePreviewAndRequestDateInputs } from "utils/hooks/usePreviewAndRequestDateInputs";

export interface EditorialHeroBannerWrapperProps extends ComponentProps {
  uriContext?: UriContextStore;
  templateComponent?: EditorialHeroBannerWrapperTemplateComponent;
}

export interface EditorialHeroBannerWrapperTemplateComponent extends ExtendedTemplateComponent {
  config: EditorialHeroBannerWrapperConfig;
}

export interface EditorialHeroBannerWrapperConfig extends ExtendedTemplateComponentConfig {
  contentTopic: string;
  style?: HeroBannerViews;
}

export const EditorialHeroBannerWrapper = inject(
  "uriContext",
  "context"
)(
  observer((props: EditorialHeroBannerWrapperProps) => {
    const { context, uriContext: uriContextStore, templateComponent } = props;
    const analyticsData = mapAnalyticsData(context, templateComponent);
    const { previewParam, requestDateParam } = usePreviewAndRequestDateInputs();

    /* istanbul ignore if */
    if (!templateComponent || !uriContextStore) {
      return null;
    }

    const uriContext = uriContextStore.getOrConvert(context);
    const isExperiencePath = !!context.experienceContext;

    const {
      config: { contentTopic, style: view },
    } = templateComponent;

    const hero = (
      <HeroBanner
        inputs={{
          uriContext: uriContext as React.ComponentProps<typeof HeroBanner>["inputs"]["uriContext"],
          contentTopic,
          experienceType: "richTextMediaActions",
          preview: previewParam,
          requestDate: requestDateParam,
        }}
        view={view}
        analyticsData={analyticsData}
      />
    );

    // Do not wrap on experience path
    /* istanbul ignore if */
    if (isExperiencePath) {
      return hero;
    }

    // Extra div is required to prevent styling of HeroBanner from being messed up on flex path
    return <div>{hero}</div>;
  })
);

export default EditorialHeroBannerWrapper;
